.moduleBtn{
    background-color: transparent;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.activeModuleBtn{
    background-color: #EAF4FF;
    padding: 0.5rem 1rem;
    border: 1px solid #1F6DDE;
    border-radius: 0.9rem;
    cursor: pointer;
}

.selectedFolder{
    border: 1px solid #1F6DDE;
    color: #1F6DDE;
}